import { env } from 'echarts';
import { createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
//#region Mobile
            {
                path: '/dirverlocation',
                name: 'driverLocation',
                component: {
                    component: () => import('../views/web/driver/dirverlocation.vue'),
                },
            },
            {
                path: "/forget",
                name: "forgetPage",
                component: ()=> import("../views/web/forget.vue"),
                meta: {authentication:false, keeplive: false},
            },
//#endregion
//#region Web
    {
        path: '/',
        name: 'index',
        component: () => import('../views/web/index.vue'),
        meta: { title: '嘉腾巴士' },
        children:[
            {
                path: "/",
                name: "homePage",
                component: ()=> import("../views/web/home.vue"),
                meta: {authentication:false, keeplive: false},
            },
            {
                path: "/login/:type",
                name: "loginParamPage",
                component: ()=> import("../views/web/login.vue"),
                meta: {authentication:false, keeplive: false},
            },
            {
                path: "/login",
                name: "loginPage",
                component: ()=> import("../views/web/login.vue"),
                meta: {authentication:false, keeplive: false},
            },
            {
                path: "/register",
                name: "registerPage",
                component: ()=> import("../views/web/register.vue"),
                meta: {authentication:false, keeplive: false},
            },
            {
            path: "/help",
            name: "helpDefaultPage",
            component: ()=> import("../views/web/help.vue"),
            meta: {authentication:false, keeplive: false},
            },
            {
            path: "/help/:type",
            name: "helpParamPage",
            component: ()=> import("../views/web/help.vue"),
            meta: {authentication:false, keeplive: false},
            },
            {
            path: "/partners",
            name: "partnersPage",
            component: ()=> import("../views/web/partners/index.vue"),
            meta: {authentication:true, keeplive: false},
            children: [
                {
                    path: "/partners",
                    name: "partnersMainPage",
                    component: ()=> import("../views/web/partners/main.vue"),
                    meta: {authentication:true, keeplive: false},
                }
            ]
            },
            {
            path: "/client",
            name: "clientPage",
            component: ()=> import("../views/web/client/index.vue"),
            meta: {authentication:true, keeplive: false},
            children: [
                {
                    path: "/client",
                    name: "clientBusinessPage",
                    component: ()=> import("../views/web/client/business.vue"),
                    meta: {authentication:true, keeplive: false},
                },
                {
                    path: "/client/cars",
                    name: "clientCarsPage",
                    component: ()=> import("../views/web/client/cars.vue"),
                    meta: {authentication:true, keeplive: false},
                },
                {
                    path: "/client/riding",
                    name: "clientRidingPage",
                    component: ()=> import("../views/web/client/riding.vue"),
                    meta: {authentication:true, keeplive: false},
                },
                {
                    path: "/client/info",
                    name: "clientInfoPage",
                    component: ()=> import("../views/web/client/info.vue"),
                    meta: {authentication:true, keeplive: false},
                },
            ],
            },
            {
            path: "/rental",
            name: "RentalPage",
            component: ()=> import("../views/web/rental.vue"),
            meta: {authentication:false, keeplive: false},
            },
            {
            path: "/cars",
            name: "CarsPage",
            component: ()=> import("../views/web/cars.vue"),
            meta: {authentication:false, keeplive: false},
            },
            {
            path: "/shoppingcart",
            name: "ShoppingCartPage",
            component: ()=> import("../views/web/shoppingcart.vue"),
            meta: {authentication:false, keeplive: false},
            },
        ]
    },
//#endregion

//#region manager 
  {
    path: "/manager",
    name: "managerIndex",
    component: ()=> import('../views/manager/index.vue'),
    meta: {authentication:true, keeplive: false, title: 'JiatengBus Administration'},
    children: [
        {
            path: "/testmap",
            name: "TestMap",
            component: ()=> import("../views/manager/testmap.vue"),
            meta: {authentication:false, keeplive: false},
        },


      {
        path: "/manager",
        name: "welcomePage",
        component: ()=> import("../views/manager/welcome.vue"),
        meta: {authentication:false, keeplive: false},
      },
      {
        path: "/manager/order/payment",
        name: "ManagerRemotePayment",
        component: ()=> import("../views/manager/order/remote.vue"),
        meta: {authentication:true, keeplive: false},
      },
      {
        path: "/manager/order/stat",
        name: "managerStat",
        component: ()=> import("../views/manager/order/stat.vue"),
        meta: {authentication:true, keeplive: false},
      },
      {
        path: "/manager/order/index",
        name: "managerOrder",
        component: ()=> import("../views/manager/order/index.vue"),
        meta: {authentication:true, keeplive: false},
      },
      {
        path: "/manager/parameters/index",
        name: "managerParameters",
        component: ()=> import("../views/manager/parameters/index.vue"),
        meta: {authentication:true, keeplive: false},
      },
      {
        path: "/manager/parameters/setting",
        name: "managerSetting",
        component: ()=> import("../views/manager/parameters/setting.vue"),
        meta: {authentication:true, keeplive: false},
      },
      {
        path: "/manager/parameters/config",
        name: "managerConfig",
        component: ()=> import("../views/manager/parameters/config.vue"),
        meta: {authentication:true, keeplive: false},
      },
      {
        path: "/manager/parameters/sms",
        name: "managerSMS",
        component: ()=> import("../views/manager/parameters/sms.vue"),
        meta: {authentication:true, keeplive: false},
      },
      {
        path: "/manager/parameters/package",
        name: "managerPackage",
        component: ()=> import("../views/manager/parameters/package.vue"),
        meta: {authentication:true, keeplive: false},
      },
      {
        path: "/manager/parameters/sysmenu",
        name: "managerSysmenu",
        component: ()=> import("../views/manager/parameters/sysmenu.vue"),
        meta: {authentication:true, keeplive: false},
      },
      {
        path: "/manager/parameters/rights",
        name: "managerRights",
        component: ()=> import("../views/manager/parameters/rights.vue"),
        meta: {authentication:true, keeplive: false},
      },
      {
        path: "/manager/parameters/role",
        name: "managerRole",
        component: ()=> import("../views/manager/parameters/role.vue"),
        meta: {authentication:true, keeplive: false},
      },
      {
        path: "/manager/parameters/file",
        name: "managerFile",
        component: ()=> import("../views/manager/parameters/file.vue"),
        meta: {authentication:true, keeplive: false},
      },
      /* finance */
      {
        path: "/manager/finance",
        name: "managerFinance1",
        component: ()=> import("../views/manager/finance/index.vue"),
        meta: {authentication:true, keeplive: false},
      },
      {
        path: "/manager/finance/index",
        name: "managerFinance2",
        component: ()=> import("../views/manager/finance/index.vue"),
        meta: {authentication:true, keeplive: false},
      },
      {
        path: "/manager/finance/verify",
        name: "managerVerify",
        component: ()=> import("../views/manager/finance/verify.vue"),
        meta: {authentication:true, keeplive: false},
      },
      {
        path: "/manager/parameters/doc",
        name: "managerDocument",
        component: ()=> import("../views/manager/parameters/doc.vue"),
        meta: {authentication:true, keeplive: false},
      },
      {
        path: "/manager/car",
        name: "managerCarHome",
        component: ()=> import("../views/manager/car/index.vue"),
        meta: {authentication:true, keeplive: false},
      },
      {
        path: "/manager/car/index",
        name: "managerCar",
        component: ()=> import("../views/manager/car/index.vue"),
        meta: {authentication:true, keeplive: false},
      },
      {
        path: "/manager/car/device",
        name: "managerDevice",
        component: ()=> import("../views/manager/car/device.vue"),
        meta: {authentication:true, keeplive: false},
      },
      {
        path: "/manager/car/insurance",
        name: "managerInsurance",
        component: ()=> import("../views/manager/car/insurance.vue"),
        meta: {authentication:true, keeplive: false},
      },
      {
        path: "/manager/car/accident",
        name: "managerAccident",
        component: ()=> import("../views/manager/car/accident.vue"),
        meta: {authentication:true, keeplive: false},
      },
      {
        path: "/manager/car/service",
        name: "managerService",
        component: ()=> import("../views/manager/car/service.vue"),
        meta: {authentication:true, keeplive: false},
      },

      {
        path: "/manager/member/enterprise",
        name: "managerEnterprise",
        component: ()=> import("../views/manager/member/enterprise.vue"),
        meta: {authentication:true, keeplive: false},
      },
      {
        path: "/manager/member/clientcode",
        name: "managerClientCode",
        component: ()=> import("../views/manager/member/clientcode.vue"),
        meta: {authentication:true, keeplive: false},
      },
      
      {
        path: "/manager/member/staff",
        name: "managerStaff",
        component: ()=> import("../views/manager/member/staff.vue"),
        meta: {authentication:true, keeplive: false},
      },
      {
        path: "/manager/member/driver",
        name: "managerDriver",
        component: ()=> import("../views/manager/member/driver.vue"),
        meta: {authentication:true, keeplive: false},
      },
      {
        path: "/manager/member/index",
        name: "managerMember",
        component: ()=> import("../views/manager/member/index.vue"),
        meta: {authentication:true, keeplive: false},
      },
      {
        path: '/:pathMatch(manager/.*)', component: ()=> import('../views/manager/nofound.vue'),
      }
    ],
  },
//#endregion
    {
        path: "/client/gps",
        name: "clientGPSPage",
        component: ()=> import("../views/web/client/gps.vue"),
        meta: {authentication:false, keeplive: false},
    },
    {
        path: "/manager/signin",
        name: "managerSignIn",
        component: ()=> import('../views/manager/signin.vue'),
        meta: {authentication:false, keeplive: false}
    },
]

const router = createRouter({
  history: createWebHashHistory(), //createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if(to.meta.hasOwnProperty('authentication') && to.meta.authentication == true){
    let token = localStorage.getItem('authorization');
    let type = localStorage.getItem('type');

    if (token === null || token === '') {
      if(to.fullPath.indexOf('/manager') > -1 && type == 'staff'){
        next({name: "loginParamPage", params: {type: 'sfmanage'} });
      }else{
        if(to.fullPath.indexOf('/client') > -1 && type == 'client'){
            next({name: "loginParamPage", params: {type: 'ka'} });
        }else if(to.fullPath.indexOf('/partners') > -1 && type == 'partner'){
            next({name: "loginParamPage", params: {type: 'partner'} });
        }else{
            next({name: "loginParamPage", params: {type: 'member'} });
        }
      }
    } else {
      next();
    }
  }else{
    next();
  }

  if(to.meta.hasOwnProperty('title') && to.meta.title != ''){
    document.title = String(to.meta.title) ;
  }else{
    if(to.fullPath.indexOf('/manager')>-1){
        document.title = '后台管理-嘉腾巴士';
    }else{
        document.title = '嘉腾巴士';
    }
  }
});

export default router
