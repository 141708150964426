import { ElMessage, ElMessageBox } from "element-plus";
import ajax from "@/http/index";
import DbHelper from "@/untils/BLL";
import Sortable from 'sortablejs';
import { Socket }  from '@/plugins/websocket';
import { Store } from 'vuex';

export enum TMapType {
    address = 'getAddress',
    drive = 'getDrive'
}

export enum fileTypes {
    login = '/storage/uploadfiles/sys/login-image.jpg',
    wxofficial = '/storage/uploadfiles/sys/wx-official.png',
    wxmini = '/storage/uploadfiles/sys/wx-mini.png'
}

export interface Tree {
    label: string
    children?: Tree[]
}

export const enum MapType {
    None,
    Search,
    Drive,
    Track,
    Fence,
}

export const basePath = process.env.VUE_APP_BASE_URL;
const appRootPath = process.env.VUE_APP_BASE_API; //'/api';

export class Helper{
    static debug = process.env.VUE_APP_DEBUG;
    static BaseUrl = process.env.VUE_APP_BASE_URL;
    static Urls = {
        /*sms */
        tencentSms: `${appRootPath}/common/setSms`,

        /* web */
        getSlideshow: appRootPath + '/common/web/slideshow',
        getOrdertypes: appRootPath + '/common/web/ordertypes',
        getFooter: appRootPath + '/common/web/footer',
        getRegion: appRootPath + '/common/getarea',
        getAddress: appRootPath + '/common/getlocation',
        getDrive: appRootPath + '/common/getdrive',
        getInvoice: appRootPath + '/common/getinvoice',
        setInvoice: appRootPath + '/common/setinvoice',
        setMemberSignup: appRootPath + '/common/setMemberSignup',
        MemberLogin: appRootPath + '/common/setMemberLogin',
        GPS: appRootPath + '/common/gps',
        getRemind: appRootPath + '/common/getremind',
        setRemind: appRootPath + '/common/setremind',
        getReconciliation: appRootPath + '/common/getreconciliation',
        getCommission: appRootPath + '/common/getcommission',
        
        setFinance: appRootPath + '/common/setfinance',
        getFinance: appRootPath + '/common/getfinance',
        exportFinance: appRootPath + '/common/exportfinance',

        getOrder: appRootPath + '/common/getorder',
        setOrder: appRootPath + '/common/setorder',
        getOrderDetails: appRootPath + '/common/getorderdetails',
        setOrderDetails: appRootPath + '/common/setorderdetails',
        getOrderPaths: appRootPath + '/common/getorderpaths',
        setOrderPaths: appRootPath + '/common/setorderpaths',
        exportOrder: appRootPath + '/common/exportorder',
        getVerify: appRootPath + '/common/getverify',
        setHistory: appRootPath + '/common/sethistory',

        pay: appRootPath + '/payment/pay',
        getQRCode: appRootPath + '/common/getQRCode',
        checkBind:appRootPath + '/common/checkBind',

        //getData: appRootPath + '/website/getjson',
        getStatic: appRootPath + '/common/website/getstaticdata',
        setData: appRootPath + '/common/website/setdata',
        getImg: appRootPath + '/common/website/getimg',
        checkUser: appRootPath + '/common/checkuser',
        getArea: appRootPath + '/common/getarea',
        getCustomer: appRootPath + '/common/getcustomer',
        downloadFile: appRootPath + '/common/downloadfile',
        
        getConfig: appRootPath + '/common/getconf',
        setConfig: appRootPath + '/common/setconf',

        getCarseat: appRootPath + '/common/getcarseat',

        getCarcost: appRootPath + '/common/getcarcost',
        setCarcost: appRootPath + '/common/setcarcost',
        getMenu: appRootPath + '/common/getmenu',
        setMenu: appRootPath + '/common/setmenu',
        setCarTrack: appRootPath + '/common/setcartrack',
        getCarTrack:appRootPath + '/common/getcartrack',
        setClientCode: appRootPath + '/common/setclientcode',
        getClientCode:appRootPath + '/common/getclientcode',
        getDevice:appRootPath + '/api/gps',
        sendmail: appRootPath + '/api/sendmail',

        getHistory: appRootPath + '/common/gethistory',

        getRights: appRootPath + '/common/getrights',
        setRights: appRootPath + '/common/setrights',
        getRole: appRootPath + '/common/getrole',
        setRole: appRootPath + '/common/setrole',
        getGroup: appRootPath + '/common/getgroup',
        setGroup: appRootPath + '/common/setgroup',
        getStaff: appRootPath + '/common/getstaff',
        setStaff: appRootPath + '/common/setstaff',
        getCar: appRootPath + '/common/getcar',
        setCar: appRootPath + '/common/setcar',
        getDriver: appRootPath + '/common/getdriver',
        setDriver: appRootPath + '/common/setdriver',
        getUserRoles: appRootPath + '/common/getuserroles',
        setUserRoles: appRootPath + '/common/setuserroles',
        getRoleRights: appRootPath + '/common/getrolerights',
        setRoleRights: appRootPath + '/common/setrolerights',
        getRightsByMenu: appRootPath + '/common/getrightsbymenu',
        getEnterprise: appRootPath + '/common/getenterprise',
        setEnterprise: appRootPath + '/common/setenterprise',
        getService: appRootPath + '/common/getservice',
        setService: appRootPath + '/common/setservice',
        getServiceDetails: appRootPath + '/common/getservicedetails',
        setServiceDetails: appRootPath + '/common/setservicedetails',
        getInsurance: appRootPath + '/common/getinsurance',
        setInsurance: appRootPath + '/common/setinsurance',
        getInsuranceDetails: appRootPath + '/common/getinsurancedetails',
        setInsuranceDetails: appRootPath + '/common/setinsurancedetails',
        getAccident: appRootPath + '/common/getaccident',
        setAccident: appRootPath + '/common/setaccident',
        getContent: appRootPath + '/common/getcontent',
        setContent: appRootPath + '/common/setcontent',
        getMember: appRootPath + '/common/getmember',
        setMember: appRootPath + '/common/setmember',
        getOrderParams: appRootPath + '/common/getorderparams',
        getOrderOffset: appRootPath + '/common/getorderoffset',
        getFee: appRootPath + '/common/getorderfee',
        setFee: appRootPath + '/common/setorderfee',
        getFile: appRootPath + '/common/getfile',
        setFile: appRootPath + '/common/setfile',
        uploadfile: appRootPath + '/common/uploadfile',
        getStatistics: appRootPath + '/common/getstatistics',
        getPackage: appRootPath + '/common/getcarcost',
        
        SignIn: appRootPath + '/common/signin',
        getImage: appRootPath + '/common/getimg',

        /* web */
        getQRInfo: appRootPath + "/common/getQRInfo",
        setCheckSignIn: appRootPath + "/common/checkSignIn",
        webManger: "/manager",

        webImagePath: appRootPath + '/common/getimg',
    } as any;

    static Debug = (args:any, t:string)=>{
        if (process.env.NODE_ENV === 'development') {
            console.log(`DEBUG: ==== ${t} ====`, args);
        }
    }

    static Patterns = {
        VALID_PASSWORD: /^[a-zA-Z0-9_]\w{6,20}$/,
        VALID_MOBILE: /^(13[0-9]|14[5-9]|15[0-3,5-9]|16[2,5,6,7]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/,
        VALID_MAIL: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
        VALID_IDENTITY: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
        VALID_NUMPLATE: /^[京津冀晋蒙辽吉黑沪苏浙皖闽赣鲁豫鄂湘粤桂琼川贵云渝藏陕甘青宁新]{1}[ABCDEFGHJKLMNOPQRSTUVWXY]{1}[0-9A-Z]{5}$/,
        VALID_NUMBER: /^[+]?((\d*(\.\d{1,2})$)|(\d+$))/,
    }

    static holidays = {
        Y2024: [{s:'2023-12-30', e:'2024-01-01'}, {s:'2024-02-10', e:'2024-02-17'}, {s:'2024-04-04', e:'2024-04-06'}, {s:'2024-05-01', e:'2024-05-05'}, {s:'2024-06-08', e:'2024-06-10'}, {s:'2024-09-15', e:'2024-09-17'}, {s:'2024-10-01', e:'2024-10-07'}]
    }

    static getVal(o:any, d:any=null):any{
        if(typeof(o)=='string'){
            if(Helper.isJson(o)){
                if(Object.keys(JSON.parse(o)).length == 0){
                    o = '';
                }
            }
        }

        if(o===undefined||o===null||o===''){
            if(d!==null)
                return d;
            else
                return null;
        }
        return o;
    }

    static isJson(item:any):boolean{
        try{
            return typeof item === 'object' && item !== null && !Array.isArray(item) && item.constructor === Object;
        }catch(e){}
        return false;
    }

    static isGuid(e:any){
        let regex1:any = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
        let regex2:any = /^[0-9a-f]{32}$/i;
        return (regex1.test(e) || regex2.test(e));
    }

    // 密码符合复杂性要求
    static checkPasswordComplexity(password:string, minLength:number=8) {
        const hasUpperCase = /[A-Z]/;
        const hasLowerCase = /[a-z]/;
        const hasNumbers = /\d+/;
        const hasSpecialChars = /[\W_]/;
       
        // 检查密码长度
        if (password.length < minLength) {
          return '密码太短';
        }
        // 检查是否包含大写字母
        if (!hasUpperCase.test(password)) {
          return '需要包含大写字母';
        }
        // 检查是否包含小写字母
        if (!hasLowerCase.test(password)) {
          return '需要包含小写字母';
        }
        // 检查是否包含数字
        if (!hasNumbers.test(password)) {
          return '需要包含数字';
        }
        // 检查是否包含特殊字符
        if (!hasSpecialChars.test(password)) {
          return '需要包含特殊字符';
        }
       
        return '';
      }

    static isMobile(mobile:string):boolean{
        if(this.getVal(mobile)==null) return false;

        let reg = new RegExp("^((13[0-9])|(14[5,7,9])|(15[^4])|(18[0-9])|(17[0,1,3,5,6,7,8]))\\d{8}$");
        return reg.test(mobile);
    }

    static getSize(val:number):string{
        if(val / 1024 / 1024 > 1){
            return (val / 1024 / 1024).toFixed(1) + ' MB';
        }else if(val / 1024 > 1){
            return (val / 1024).toFixed(0) + ' KB';
        }else{
            return val + ' B';
        }
    }

    static amount(number:any, decimalPlaces:number=2, decimalSeparator:string = ',', thousandSeparator:string='.', ) {
        number = isNaN(number) ? 0 : Number(number);
        const options = {
          minimumFractionDigits: decimalPlaces,
          maximumFractionDigits: decimalPlaces,
          useGrouping: true,
          style: 'decimal',
        } as any;
        
        if (thousandSeparator) {
          options.grouping = thousandSeparator;
        }
        
        if (decimalSeparator) {
          options.decimal = decimalSeparator;
        }
        
        return new Intl.NumberFormat('en-US', options).format(number);
    }
    
    static formatdate(d:any,fmt?:string):string{
        if(!d) return '';
        if(!fmt) fmt = "yyyy-MM-dd";
        switch(typeof d){
            case "string":
                d = new Date(d.replace(/-/g, "/"));
                break;
            case "number":
                d = new Date(d);
                break;
        }

        if(d instanceof Date){
            const dict:any = {
                yyyy: d.getFullYear(),
                M:d.getMonth()+1,
                d:d.getDate(),
                H:d.getHours(),
                m:d.getMinutes(),
                s:d.getSeconds(),
                MM:('' + (d.getMonth() + 101)).substring(1),
                dd:('' + (d.getDate() + 100)).substring(1),
                HH:('' + (d.getHours() + 100)).substring(1),
                mm:('' + (d.getMinutes() + 100)).substring(1),
                ss:('' + (d.getSeconds() + 100)).substring(1)
            };
            return fmt.replace(/(yyyy|MM?|dd?|HH?|ss?|mm?)/g, function(){
                return dict[arguments[0]];
            });
        }

        return '';
    }

    static getDateByMonth(y:any=null){
        let td:Date;
        if(y!=null){
            td = new Date(y);
        }else{
            td = new Date();
        }

        let nd:Date = td.getMonth() + 1 > 11 ? new Date( td.getFullYear() + 1, 0, 1) : new Date( td.getFullYear(), td.getMonth() + 1, 1);
        let cl:Date = new Date(nd.getTime() - 60 * 1000);
        
        return Array.from({length: Math.max(cl.getDate(), 1)}, ()=>0).map((item:number, index:number)=>{ return `${cl.getFullYear()}-${cl.getMonth()+1}-${index + 1}`;});
    }

    static getDateByWeek(y:any=null, m:any=null, d:any = null){
        let td:Date;
        if(m!=null && d!=null){
            td = new Date(y, m, d);
        }else if(y!=null){
            td = new Date(y);
        }else{
            td = new Date();
        }
        let tw:number = td.getDay();

        let _s:Date = new Date(td.getFullYear(), td.getMonth(), td.getDate() - tw - (tw == 0 ? 6 : -1));

        let _arr:Array<string> = [];

        for(var i = 0; i < 7; i++){
            _arr.push(Helper.formatdate(new Date(_s.getTime() + 24 * 60 * 60 * 1000 * i)));
        }

        return _arr;
    }

    static insertCharAt(str:string, pos:number = 2, c:string = ' '){
        return str.slice(0, pos) + c + str.slice(pos)
    }

    static getTime(s:number, t:string=''):string{
        switch(t){
            case 'd':
                return Helper.amount(s / 60 / 60 / 24, 2, '.', ',') +  '天';
            case 'h':
                return Helper.amount(s / 60 / 60, 2, '.', ',') +  '小时';
            case 'm':
                return Helper.amount(s / 60, 2, '.', ',') +  '分钟';
            case 's':
                return Helper.amount(s, 2, '.', ',') +  '秒';
            default:
                if(s / 60 / 60 / 24 > 1){
                    return Helper.getTime(s, 'd');
                }else if(s / 60 / 60 > 1){
                    return Helper.getTime(s, 'h');
                }else if(s / 60 > 1){
                    return Helper.getTime(s, 'm');
                }else{
                    return Helper.getTime(s, 'd');
                }
        }
    }

    static getImageFile(id:string, h:number, o:string='o', m:number=0, n:boolean=false):string{
        let _url = '';

        if(o==''){
            _url = `&h=${h}&id=${id}`;
        }else{
            _url = `&h=${h}&oid=${id}`;;
        }

        if(n) {
            _url = `${_url}&t=${new Date().getTime()}`;
        }

        return `${Helper.getUrls('getImg')}?m=${m}${_url}`;
    }
    
    static getUrls(opt:string){
        let url:string = Helper.getVal(Helper.Urls[opt], '');
        if(url === '') throw opt + ' Error, Url is not exists';
        return url;
    }

   /**
     * 销售额-阶梯算法
     *
     * @name getBonus
     * @param {Number} sale - 当前销售额.
     * @param {Array<any>} datalist - 阶梯提成结构. 
     * {limit: 1000, rate: 0.01} 结构为JSON数组，包括阶梯额度(limit)，应用提成比例(rate).
     * @return {Number} - 返回销售提成
     * 
     * @example
     *
     * let bonus:number = getBonus(12000, [ ... {limit: 10000, rate: 0.04} ... {limit: 0, rate: 0.02}])
     * 
    **/
    static getBonus(sale:number, datalist:Array<any>):number{
        let bonus:number = 0;

        datalist.sort((a:any, b:any)=> b.limit - a.limit);

        datalist.forEach((item:any, index:number)=>{
            if(sale>item.limit){
                bonus += (sale - item.limit) * item.rate;
                if(index < datalist.length - 1) {
                    sale = item.limit;
                }
            }
        });

        return bonus;
    }

    /**
     * 判断是否在多边形之内。
     * 
     * @param point 当前点坐标
     * @param vertices 多边形顶点集合
     * @returns boolean
     * 如果在多边形内，true; 如果在多边形外， false;
     */
    static isPointInsidePolygon(point:Array<any>, vertices:Array<any>):boolean{
        const x = point[0];
        const y = point[1];

        let intersections:number = 0;
        for (let i = 0; i < vertices.length; i++) {
            const vertex1 = vertices[i];
            const vertex2 = vertices[(i + 1) % vertices.length];

            if (vertex1[1] === vertex2[1] && y === vertex1[1]) {
                if (x >= Math.min(vertex1[0], vertex2[0]) && x <= Math.max(vertex1[0], vertex2[0])) {
                    return true; // On edge
                }
                continue; // Parallel lines
            }

            const intersectY = vertex1[1] < vertex2[1] ? Math.max(vertex1[1], y) : Math.min(vertex1[1], y);
            if (y > intersectY) {
                const intersectX = (y - vertex1[1]) * (vertex2[0] - vertex1[0]) / (vertex2[1] - vertex1[1]) + vertex1[0];
                if (intersectX > x) {
                    intersections++;
                }
            }
        }
        return intersections % 2 === 1; // Odd number of intersections means inside
    }

    /*
        设置列表排序
    */
    static setOrder(index:number, arr:Array<any>, status:number=0){
        const _data:Array<any> = JSON.parse(JSON.stringify(arr));

        switch(status){
            case 0:
                if(index!=0){
                    _data.splice(index-1, 2, arr[index], arr[index-1]);
                }
                break;
            case 1:
                if(index!=arr.length){
                    _data.splice(index, 2, arr[index + 1], arr[index]);
                }
                break;
        }

        return _data;
    }

    /*
        检查权限
    */
    static checkRights(rights:string, rightskey:string, data:any){
        let _arr:Array<string> = rightskey.split(",");

        if(rights == 'system-all'){
            Object.getOwnPropertyNames(data).forEach(key=>{
                data[key] = true;
            });

            return;
        }

        rights = rights.replace(/\-/gi, '_');
        Object.getOwnPropertyNames(data).forEach(key=>{
            data[key] = rights.indexOf(key) > -1;
        });
    }
}

export class websocketHelper {
    private socket:any = new Socket();

    get socketMessage(){
        return this.socket.getMessage();
    }

    //user websocket
    socketInit(){
        let _path:any = Helper.BaseUrl;
        this.socket.initWebSocket(_path.replace('http', 'ws') + ':20002');
        //this.socket.addEventListener('message', this.socket.webSocketMessage);
    }

    socketSend(data:any){
        this.socket.websocketSend(data);
    }

    socketClose(){
        this.socket.websocketClose();
        this.socket.removeEventListener('message', this.socket.webSocketMessage);
    }
}

export class commonHelper {
    static sms(option:any){
        option.type = 'tencentSms';
        return DbHelper.set(option);
    }
    static getHistory(option:any){
        option.type = 'getHistory';
        return DbHelper.get(option);
    }

    static setHistory(option:any){
        option.type = 'setHistory';
        return DbHelper.get(option);
    }

    static getExcel(option:any){
        option.type = 'getExcel';
        return DbHelper.download(option);
    }

    static sendEmail(option:any){
        option.type = 'sendmail';
        switch(option.params.mailType){
            default:
                if(Helper.getVal(option.params.to)==null) option.params.to =  'client@jiatengbus.com';
                break;
        }
        return DbHelper.set(option);
    }

    static checkBind (option:any){
        option.type = 'checkBind';
        return DbHelper.set(option);
    }

    static getQRCode(option:any){
        option.type = 'getQRCode';
        let _url:string = DbHelper.getUrls(option.type);
        option.url = _url.replace('php.jiatengbus.com', 'www.jiatengbus.com');
        return DbHelper.get(option);
    }

    static getGPS(option:any){
        option.type = 'GPS';
        return DbHelper.download(option);
    }

    static get(option:any) {
        return DbHelper.get(option);
    }

    static all(option:any){
        return DbHelper.all(option);
    }
    
    static set(option:any){
        return DbHelper.set(option);
    }
    
    static delete(option:any){
        return DbHelper.delete(option);
    }

    static checkUser(option:any){
        option.type = 'checkUser';
        return DbHelper.set(option);
    }

    static order(data:any, callback:any = null){
        Sortable.create(data.container, {
            dragClass: "sortable-drag",
            ghostClass: "sortable-ghost", 
            onEnd: (evt:any)=>{
                if(evt.oldIndex == evt.newIndex) return;
                let _data = { 'id': data.datalist[evt.oldIndex].id, 'ordernum': evt.newIndex + 1 };

                console.log('=======sortable data=======', _data);

                if(callback!=null) callback(_data);
            }
        });
    }

    static status(option:any, callback:any = null){
        let status:any = option.statuslist.find((item:any)=>{
            return item.groupvalue == option.statusvalue;
        });

        let _data = { 'id': option.order.id, 'statusid': status.id };

        if(callback!=null) callback(_data);
    }

    //TianDiTu
    static TMap(t:TMapType, option:any){
        switch(t){
            case TMapType.address:
                return DbHelper.get({
                    type: t,
                    loading: option.loading,
                    loading_value: option.loading_value,
                    params: { k: option.keyword}
                });
            case TMapType.drive:
                return DbHelper.get({
                    type: t,
                    loading: option.loading,
                    loading_value: option.loading_value,
                    params: { data: JSON.stringify({
                        'orig': option.orig,
                        'dest': option.dest,
                        'list': option.list
                    }) }
                });
            default:
                return new Promise((resolve, reject)=>{reject('Type error.')});
        }
    }

    //rights
    static async rights_init(option:any, callback:any=null){
        let _rights:any = {};
        /*

        if (option.stateRights == 'system-all'){
            Object.getOwnPropertyNames(_rights).forEach(key=>{
                _rights[key] = true;
            });

            if(callback!=null) callback(_rights);
            return;
        }
        */

        await rightsHelper.get({
            params: { "key": option.rightskey }
        }).then((res:any)=>{
            res.data.map.forEach((item: any) => {
                _rights[item.menukey + "_" + item.code] = false;
            });

            if (option.stateRights == 'system-all'){
                Object.getOwnPropertyNames(_rights).forEach(key=>{
                    _rights[key] = true;
                });
    
                if(callback!=null) callback(_rights);
                return;
            }

            Helper.checkRights(option.stateRights, option.rightskey, _rights);

            if (!_rights[option.rightskey.split(",")[0] + "_browse"]){
                _rights = [];
            }

            if(callback!=null) callback(_rights);
        }).catch((err:any)=>{
            console.log(err);
        });
    }

    //check right
    static checkright(option:any){
        if(option.stateRights == 'system-all') return true;

        let _header:string = option.handler.replace(/\-/g, '_');

        if(option.stateRights.replace(/\-/g, '_').indexOf(_header)>-1){
            if(_header.split('_').length - 1 > 1){
                return option.rights[option.handler];
            }else{
                return option.rights[option.rightskey + '_' + _header];
            }
        }

        return false;
    }
}

export class orderHelper {
    /*创建订单*/
    static createOrder(option:any){
        option.type = 'pay';
        return DbHelper.post(option);
    }
    /* 发送订单 */
    static payOrder(option:any){
        return DbHelper.set({
            type: 'paybywx',
            params: {'type': 'NATIVE', data: JSON.stringify(option.data) }
        });
    }

    static save(option:any){
        option.type = 'setOrder';
        return DbHelper.set(option);
    }

    static delete(option:any){
        option.type = 'setOrder';
        return DbHelper.delete(option);
    }

    static get(option:any){
        option.type = 'getOrder';
        return DbHelper.get(option);
    }

    static order(option:any){
        commonHelper.order(option, (data:any)=>{
            DbHelper.set({
                type: option.type,
                params: { type: 'order', data: JSON.stringify(data) }
            }).then((res:any)=>{
                if(option.success!=null) option.success(res);
            }).catch((err:any)=>{
                if(option.fail!=null) option.fail(err);
            });
        });
    }

    static export(option:any){
        option.type = 'exportOrder';
        return DbHelper.download(option);
    }

    static checkPayScan(option:any){
        option.type = 'checkPayScan';
        return DbHelper.set(option);
    }

    //更新状态
    static status(option:any){
        let t:any = option.t;
        
        let _type = 'setOrder';
        let _str = '';

        Helper.Debug(option, '=============更新订单状态======');

        return new Promise((resolve, reject)=>{
            switch(Number(option.statusvalue)){
                case 15:
                case 20:
                    //不允许零车辆时生效或执行
                    if(!option.order.totaldetails || option.order.totaldetails.length==0){
                        ElMessage.error({message: `未发现订单有车辆被派遣，请先派车。`});
                        return;
                    }

                    //不允许有客人数大于核定座位数
                    if(option.order.personnum > 0 && option.order.totalapprovalnum < option.order.personnum){
                        ElMessage.error({message: `还有${option.order.personnum - option.order.totalapprovalnum}人未分派车辆，无法派车。请继续添加车辆`});
                        return;
                    }

                    //不允许零费用生效或执行
                    if(option.order.totaldone <= 0){
                        ElMessage.error({message: `未发现本订单有通过审核的收款记录，订单无法${option.statusvalue == 15 ? '生效': '执行'}。`});
                        return;
                    }

                    //不允许路径为空
                    if(!option.order.totalpaths || option.order.totalpaths==0){
                        ElMessage.error({message: `短信通知客户和驾驶员需要发车地点。请在路径中添加。`});
                        return;
                    }

                    //如果是套餐，必须在生效前结清费用。
                    if(Helper.getVal(option.order.package)!=null){
                        if(option.order.totalapprovalnum==0 || option.order.totalpaths < 2){
                            ElMessage.error({message: `本订单为套餐模式, 必须至少有2个途经点。`});
                            return;
                        }
                        if(option.order.totalprice != option.totaldone ){
                            ElMessage.error({message: `本订单为套餐模式, 须在生效前，客户结清套餐应结费用。`});
                            return;
                        }
                    }
                    if(option.statusvalue == 15){
                        _str = '本操作将确认车单信息完备。需要客户确认并支付本车单相关费用后，本车单将自动转为订单生效。';
                    }else{
                        let _tmp = '';
                        option.allowSms = true;
                        if(new Date(option.order.startdatetime).getTime() < new Date().getTime()){
                            _tmp = `<p style="color: red;">注意!!! 本订单生效操作时，发车时间已经过期。请在生效后，再次检查本订单!</p>`;
                            option.allowSms = false;
                        }
                        _str = `${_tmp}<p>本操作将确认订单正式生效。正式生效后，将分别向客户与驾驶员发送短信通知。</p><p>当车辆在起始日期"${Helper.formatdate(option.order.startdatetime,'yyyy年MM月dd日 HH:mm')}"开始任务时，如驾驶员遗忘修改状态，您需要立即修改订单状态为：执行中。</p>`;
                    }
                    break;
                case 30:
                    _str = '本操作将确认订单开始执行。请确认已选配车辆且车辆已出发。';
                    break;
                case 70:
                    _str = '本操作将确认此订单下所有车辆均完成客运任务。并等待客户结算。';
                    break;
                case 80:
                    if(option.order.hasverifyreceiver > 0){
                        ElMessage.error({message: `本订单客户支付有未审核记录，无法进行结算。`});
                        return;
                    }

                    /*
                        检查结算是否完成： 账单费用与收款费用是否相等
                    */
                    if(option.order.totalprice != option.order.totaldone){
                            ElMessage.error({
                                message: `账单金额（￥${Helper.getVal(option.order.totalprice,0)}）与收入金额（￥${Helper.getVal(option.order.totaldone,0)}）不付，无法完成结算操作。请首先完成填写费用清单，账单费用与收款费用平衡后再继续操作。`
                            });
                            return;
                    }

                    _str = '本操作将确认客户已结算此订单，并向客户发送短信通知。确认后，将等待向合作方结算。如果无须向合作方结算，请选择“取消”按钮，在工具中选择“订单结算”。';
                    option.allowSms = true;
                    break;
                case 85:
                    /*
                        检查成本费用是否和发款相等
                    */
                    if(option.order.totalprice != option.order.totaldone || option.order.totalcost != option.order.totalpay){
                        ElMessage.error({
                            message: `成本金额（￥${Helper.getVal(option.order.totalcost, 0)}）与发款金额（￥${Helper.getVal(option.order.totalpay, 0)}）不付，无法完成向第三方结算操作。请首先完成填写费用清单，向第三方结转后再继续操作。`
                        });
                        return;
                    }
                    
                    if(option.order.hasverifytotal > 0){
                        ElMessage.error({message: `本订单有未审核记录，无法取消。`});
                        return;
                    }
                    _str = '本操作将取消此订单下的所有车辆任务。请先确认所有订单明细下的相关费用是否正确，在费用列表中是否已填写取消订单费用并经审核。是否继续? ';
                    break;
                case 90:
                    /*
                        检查成本费用是否和发款相等
                    */
                    if(Helper.getVal(option.order.totalpay)==null || option.order.totalcost != option.order.totalpay){
                        ElMessage.error({
                            message: `成本金额（￥${Helper.getVal(option.order.totalcost, 0)}）与发款金额（￥${Helper.getVal(option.order.totalpay, 0)}）不付，无法完成向第三方结算操作。请首先完成填写费用清单，向第三方结转后再继续操作。`
                        });
                        return;
                    }

                    if(option.order.hasverifytotal > 0){
                    ElMessage.error({message: `本订单有未审核记录，无法结算。`});
                    return;
                    }

                    _type = 'setOrder';
                    _str = '本操作将确认此订单已结算完成';
                    break;
                default:
                    ElMessage.error({message: `状态值超出预期。${option.order.statusvalue}`});
                    return;
            }

            commonHelper.status(option, (data:any)=>{
                ElMessageBox.confirm(_str, t('sys.PROPMPT'), {
                    confirmButtonText: t('sys.OK'),
                    cancelButtonText: t('sys.CANCEL'),
                    dangerouslyUseHTMLString: true,
                    type: 'warning',
                }).then(()=>{
                    DbHelper.set({
                        type: _type,
                        params: {type:'update', data: JSON.stringify(data)}
                    }).then((res:any)=>{
                        //截断发送短信
                        option.allowSms = false;
                        
                        ElMessage.success({message: `${t('sys.HANDLE_SUCCESS')} ${option.allowSms ? '正在向客户或驾驶员发送短信通知...' : ''}`});

                        /* 发送短信 */
                        if(option.allowSms){
                            let _data:any = {};
                            switch(option.statusvalue){
                                case 20: //订单生效
                                case 30: //车辆发车，执行中
                                    break;
                            }
                            commonHelper.sms({
                                params: {
                                    type: 'send',
                                    data: JSON.stringify(_data),
                                }
                            }).then((sms:any)=>{
                                ElMessage.success({message: `发送短信完成!`});
                            });
                        }
                        resolve(res);
                    }).catch((err:any)=>{
                        reject(err);
                    });
                }).catch(()=>{
                    ElMessage.info({message: t('sys.HANDLE_CANCEL')});
                });
            });
        });
    }

    static details_save(option:any){
        option.type = 'setOrderDetails';
        return DbHelper.set(option);
    }

    static details_get(option:any){
        option.type = 'getOrderDetails';
        return DbHelper.get(option);
    }

    static details_delete(option:any){
        option.type = 'setOrderDetails';
        return DbHelper.delete(option);
    }

    static verify_get(option:any){
        option.type = 'getVerify';
        return DbHelper.get(option);
    }
    
    static fee_get(option:any){
        option.type = 'getFee';
        return DbHelper.get(option);
    }
    
    static fee_save(option:any){
        option.type = 'setFee';
        return DbHelper.set(option);
    }
    
    static fee_delete(option:any){
        option.type = 'setFee';
        return DbHelper.delete(option);
    }
    
    static path_get(option:any){
        option.type = 'getOrderPaths';
        return DbHelper.get(option);
    }

    static path_save(option:any){
        option.type = 'setOrderPaths';
        return commonHelper.set(option);
    }
    
    static path_delete(option:any){
        option.type = 'setOrderPaths';
        option.params.type = 'delete';
        if(Helper.getVal(option.t)!=null) option.hasAsk = true;
        return DbHelper.delete(option);
    }

    static path_order(option:any){
        option.type = 'setOrderPaths';
        return commonHelper.set(option);
    }

    static offset_get(option:any){
        option.type = 'getOrderOffset';
        return commonHelper.set(option);
    }

    static commission_get(option:any){
        option.type = 'getCommission';
        return DbHelper.get(option);
    }

    static reconciliation_get(option:any){
        option.type = 'getReconciliation';
        return DbHelper.get(option);
    }
}

//car
export class carHelper {
    static save(option:any){
        option.type = 'setCar';
        return DbHelper.set(option);
    }
    static delete(option:any){
        option.type = 'setCar';
        return DbHelper.delete(option);
    }

    static get(option:any){
        option.type = 'getCar';
        return DbHelper.get(option);
    }
}

//driver
export class driverHelper {
    static save(option:any){
        option.type = 'setDriver';
        return DbHelper.set(option);
    }
    static delete(option:any){
        option.type = 'setDriver';
        return DbHelper.delete(option);
    }

    static get(option:any){
        option.type = 'getDriver';
        return DbHelper.get(option);
    }
}

//member
export class memberHelper {
    static save(option:any){
        option.type = 'setMember';
        return DbHelper.set(option);
    }

    static delete(option:any){
        option.type = 'setMember';
        return DbHelper.delete(option);
    }

    static get(option:any){
        option.type = 'getMember';
        return DbHelper.get(option);
    }

    static customer_get(option:any){
        option.type = 'getCustomer';
        return DbHelper.get(option);
    }
}

//staff
export class staffHelper {
    static save(option:any){
        option.type = 'setStaff';
        return DbHelper.set(option);
    }

    static delete(option:any){
        option.type = 'setStaff';
        return DbHelper.delete(option);
    }

    static get(option:any){
        option.type = 'getStaff';
        return DbHelper.get(option);
    }
}

//enterprise
export class enterpriseHelper {
    static save(option:any){
        option.type = 'setEnterprise';
        return DbHelper.set(option);
    }

    static delete(option:any){
        option.type = 'setEnterprise';
        return DbHelper.delete(option);
    }

    static get(option:any){
        option.type = 'getEnterprise';
        return DbHelper.get(option);
    }
}

export class invoiceHelper {
    static save(option:any){
        option.type = 'setInvoice';
        return DbHelper.set(option);
    }

    static delete(option:any){
        option.type = 'setInvoice';
        return DbHelper.delete(option);
    }

    static get(option:any){
        option.type = 'getInvoice';
        return DbHelper.get(option);
    }
}

//finance
export class financeHelper {
    static save(option:any){
        option.type = 'setFinance';
        return DbHelper.set(option);
    }
    
    static delete(option:any){
        option.type = 'setFinance';
        return DbHelper.delete(option);
    }

    static get(option:any){
        option.type = 'getFinance';
        return DbHelper.get(option);
    }

    static export(option:any){
        option.type = 'exportFinance';
        return DbHelper.download(option);
    }
}

export class serviceHelper {
    static save(option:any){
        option.type = 'setService';
        return DbHelper.set(option);
    }
    
    static delete(option:any){
        option.type = 'setService';
        return DbHelper.delete(option);
    }

    static get(option:any){
        option.type = 'getService';
        return DbHelper.get(option);
    }

    static details_get(option:any){
        option.type = 'getServiceDetails';
        return DbHelper.get(option);
    }

    static details_save(option:any){
        option.type = 'setServiceDetails';
        return DbHelper.set(option);
    }

    static details_delete(option:any){
        option.type = 'setServiceDetails';
        return DbHelper.delete(option);
    }
}

export class accidentHelper {
    static save(option:any){
        option.type = 'setAccident';
        return DbHelper.set(option);
    }
    
    static delete(option:any){
        option.type = 'setAccident';
        return DbHelper.delete(option);
    }

    static get(option:any){
        option.type = 'getAccident';
        return DbHelper.get(option);
    }
}

//insurance
export class insuranceHelper {
    static save(option:any){
        option.type = 'setInsurance';
        return DbHelper.set(option);
    }
    
    static delete(option:any){
        option.type = 'setInsurance';
        return DbHelper.delete(option);
    }

    static get(option:any){
        option.type = 'getInsurance';
        return DbHelper.get(option);
    }
    
    static details_delete(option:any){
        option.type = 'setInsuranceDetails';
        return DbHelper.delete(option);
    }

    static details_get(option:any){
        option.type = 'getInsuranceDetails';
        return DbHelper.get(option);
    }

    static details_save(option:any){
        option.type = 'setInsuranceDetails';
        return DbHelper.set(option);
    }
}

//group
export class groupHelper {
    static save(option:any){
        option.type = 'setGroup';
        return DbHelper.set(option);
    }
    
    static delete(option:any){
        option.type = 'setGroup';
        return DbHelper.delete(option);
    }

    static order(option:any){
        option.type = 'setGroup';
        commonHelper.order(option, (data:any)=>{

            DbHelper.set({
                type: option.type,
                params: { type: 'order', data: JSON.stringify(data) }
            }).then((res:any)=>{
                if(option.success!=null) option.success(res);
            }).catch((err:any)=>{
                if(option.fail!=null) option.fail(err);
            });

            /* ajax.post(Helper.Urls[option.type], {type: 'order', data: JSON.stringify(data)}).then((res:any)=>{
                if(option.success!=null) option.success(res);
            }).catch((err:any)=>{
                if(option.fail!=null) option.fail(err);
            }); */
        });
    }

    static get(option:any){
        option.type = 'getGroup';
        return DbHelper.get(option);
    }
}

//menu
export class menuHelper{
    static save(option:any){
        option.type = 'setMenu';
        return DbHelper.set(option);
    }
    
    static delete(option:any){
        option.type = 'setMenu';
        return DbHelper.delete(option);
    }

    static order(option:any){
        option.type = 'setMenu';
        commonHelper.order(option, (data:any)=>{
            ajax.post(Helper.Urls[option.type], {type: 'order', data: JSON.stringify(data)}).then((res:any)=>{
                if(option.success!=null) option.success(res);
            }).catch((err:any)=>{
                if(option.fail!=null) option.fail(err);
            });
        });
    }

    static get(option:any){
        option.type = 'getMenu';
        return DbHelper.get(option);
    }
}

export class deviceHelper {
    static getDevice(option:any){
        option.type = 'getDevice';
        return DbHelper.get(option);
    }

    static save(option:any){
        option.type = 'setCarTrack';
        return DbHelper.set(option);
    }
    
    static delete(option:any){
        option.type = 'setCarTrack';
        return DbHelper.delete(option);
    }

    static get(option:any){
        option.type = 'getCarTrack';
        return DbHelper.get(option);
    }

    static getclientcode(option:any){
        option.type = 'getClientCode';
        return DbHelper.get(option);
    }

    static deleteclientcode(option:any){
        option.type = 'setClientCode';
        return DbHelper.delete(option);
    }

    static saveclientcode(option:any){
        option.type = 'setClientCode';
        return DbHelper.set(option);
    }
}

//file
export class fileHelper {
    static save(option:any){
        option.type = 'setFile';
        return DbHelper.set(option);
    }
    
    static delete(option:any){
        option.type = 'setFile';
        return DbHelper.delete(option);
    }

    static order(option:any){
        option.type = 'setFile';
        commonHelper.order(option, (data:any)=>{
            ajax.post(Helper.Urls[option.type], {type: 'order', data: JSON.stringify(data)}).then((res:any)=>{
                if(option.success!=null) option.success(res);
            }).catch((err:any)=>{
                if(option.fail!=null) option.fail(err);
            });
        });
    }

    static get(option:any){
        option.type = 'getFile';
        return DbHelper.get(option);
    }

    static getDownloadUrl(option:any){
        return `${Helper.getUrls('downloadFile')}?type=${option.type}&id=${option.id}&name=${option.name}`;
    }

    static download(option:any){
        window.location.href = fileHelper.getDownloadUrl(option);
    }
}

//rights
export class rightsHelper {
    static save(option:any){
        option.type = 'setRights';
        return DbHelper.set(option);
    }
    static delete(option:any){
        option.type = 'setRights';
        return DbHelper.delete(option);
    }

    static get(option:any){
        option.type = 'getRights';
        return DbHelper.get(option);
    }

    static menu_get(option:any){
        option.type = 'getRightsByMenu';
        return DbHelper.get(option);
    }
}
//role
export class roleHelper {
    static save(option:any){
        option.type = 'setRole';
        return DbHelper.set(option);
    }
    static delete(option:any){
        option.type = 'setRole';
        return DbHelper.delete(option);
    }

    static get(option:any){
        option.type = 'getRole';
        return DbHelper.get(option);
    }

    static user_save(option:any){
        option.type = 'setUserRoles';
        return DbHelper.set(option);
    }
    static user_delete(option:any){
        option.type = 'setUserRoles';
        return DbHelper.delete(option);
    }

    static user_get(option:any){
        option.type = 'getUserRoles';
        return DbHelper.get(option);
    }

    static rights_save(option:any){
        option.type = 'setRoleRights';
        return DbHelper.set(option);
    }
    static rights_delete(option:any){
        option.type = 'setRoleRights';
        return DbHelper.delete(option);
    }

    static rights_get(option:any){
        option.type = 'getRoleRights';
        return DbHelper.get(option);
    }
}

//content
export class contentHelper {
    static save(option:any){
        option.type = 'setContent';
        return DbHelper.set(option);
    }
    static delete(option:any){
        option.type = 'setContent';
        return DbHelper.delete(option);
    }

    static get(option:any){
        option.type = 'getContent';
        return DbHelper.get(option);
    }
}

export class statisticsHelper {
    static remind_get(option:any){
        option.type = 'getRemind';
        return DbHelper.get(option);
    }
    static remind_save(option:any){
        option.type = 'setRemind';
        return DbHelper.set(option);
    }
    static remind_delete(option:any){
        option.type = 'setRemind';
        return DbHelper.delete(option);
    }

    static get(option:any){
        option.type = 'getStatistics';
        return DbHelper.get(option);
    }
}

export class paramsHelper {
    static config_save(option:any){
        option.type = 'setConfig';
        return DbHelper.set(option);
    }
    static config_delete(option:any){
        option.type = 'setConfig';
        return DbHelper.delete(option);
    }

    static config_get(option:any){
        option.type = 'getConfig';
        return DbHelper.get(option);
    }
}

export class searchHelper {
    static get(store:Store<any>, key:any=null){
        let _data:any = store.state.search.data;

        if(Helper.getVal(_data)!=null){
            if(Helper.isJson(_data)){
                let _json:any = JSON.parse(_data);

                if(key!=null){
                    if(_json.hasOwnProperty(key)){
                        return _json[key];
                    }
                }

                return [];
            }
        }else{
            return [];
        }
    }

    static save(store:Store<any>, key:any, id:string, list:Array<any>){
        if(Helper.getVal(list)==null || list.length < 1) return;

        let obj:any = null;
        list.forEach((item:any)=>{
            if(item.hasOwnProperty('options')){
                let _obj:any = item.options.find((el:any)=>{ return el.id == id;});
                if(_obj!=null){
                    obj = _obj;
                    return;
                }
            }else{
                if(item.id == id){
                    obj = item;
                    return;
                }
            }
        });

        if(obj == null) return;

        searchHelper.set(store, key, obj);
    }

    static set(store:Store<any>, key:any, value:any){
        /*
        value:
            {id: 'c66505114fba47978ed389c3b04c07ee', caption: '小凡老师', type: 'member'}
            {id: '943ed4f830c44291b5399b079305e266', mobile: '18521015067', fullname: '王阳', dutycaption: '初级业务员', departmentcaption: '业务部'}
        */
        let _json:any = store.getters['search/getSearch'];

        let _caption:string = '';
        switch(value.type){
            case 'member':
                _caption = '个人用户';
                break;
            case 'enterprise':
                _caption = '企业用户';
                break;
        }

        if(!_json.hasOwnProperty(key)){
            if(_caption!=''){
                _json[key] = [{caption: _caption, options: []}];
            }else{
                _json[key] = [];
            }
            
        }
        let _data:any = _json[key];

        if(!value.hasOwnProperty('type')){
            if(_data.find((item:any)=>{ return item.id == value.id})!=null) return;
            
            _data.push(value);

            //数组超过5个，删除第一个
            if(_data.length > 5){
                _data.shift();
            }
        }else{

            let _index:number = _data.findIndex((item:any)=>{ return item.caption == _caption; });

            if(_index > -1){
                if(_data[_index].options.find((item:any)=>{return item.id == value.id;})!=null) return;
                _data[_index].options.push(value);

                //数组超过5个，删除第一个
                if(_data[_index].options.length > 5){
                    _data[_index].options.shift();
                }
            }else{
                _data.push({caption: _caption, options:[value]});
            }
        }
        _json[key] = _data;

        store.dispatch('search/SEARCH_SAVE', _json);
    }

    static remove(store:Store<any>, key:any){
        let _json:any = store.getters['search/getSearch'];

        if(!_json.hasOwnProperty(key)){
            delete _json[key];
        }

        store.dispatch('search/SEARCH_SAVE', _json);
    }
}

export class storageHelper {
    static order_get(store:Store<any>){
        let _data:any = store.getters['order/getOrderinfo'];

        return _data;
    }

    static order_save(store:any, value:any){
        store.dispatch('order/INFO_SAVE', value);
    }
}

export default Helper
